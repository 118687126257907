import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MyRoute from "../../../hoc/MyRoute";
import Dashboard from "../Dashboard/components/Dashboard";
import HeaderDashboard from "../../../components/Layout/Header/HeaderDashboard";
import {
  merchantDetails,
  getBannerData,
  getNotificationCount,
} from "../Dashboard/redux/action";
import {
  getProductDetails,
  clearProductList,
  clearProductView,
  clearSortBy,
  getDashProductList,
} from "../Redeem/redux/action";
import {
  resetFilter,
  removeRelatedProducts,
  applyRelatedProducts,
} from "../../../components/Filter/redux/action";
import { resetSearchText } from "../../../components/Search/redux/action";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
  setActiveTab,
} from "../../../../src/App/redux/action";
import { getStorageItem, setStorageItem } from "../../../common/localStorage";
import { apiData } from "../../../common/common-types";
import { clearBadgeCount } from "../../../common/commonFunctions";
import Loader from "../../../components/Loader/Loader";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { stockAPI } from "../../../common/axiosInstance";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";
import { isPWAInstalled } from "../../../common/commonFunctions";
import { setFromOrderFlag } from "../Redeem/redux/action";
import platform from "platform";
import { askForPermission } from "../../../firebase";

const mapDispatchToProps = {
  merchantDetails,
  getDashProductList,
  getProductDetails,
  changeActiveTab,
  logoutUser,
  showLoader,
  clearProductList,
  clearProductView,
  clearSortBy,
  resetFilter,
  resetSearchText,
  getBannerData,
  removeRelatedProducts,
  applyRelatedProducts,
  setFromOrderFlag,
  getNotificationCount,
};

const mapStateToProps = (state) => {
  return {
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    banners: state.reducerDashboard.banners,
    productList: state.reducerRedeem.dashproductList,
    productDetails: state.reducerRedeem.productDetails,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    activeTab: state.reducerApp.activeTab,
    productId: state.reducerFilter.productId,
    productMerchantId: state.reducerFilter.productMerchantId,
    isFromOrder: state.reducerProductView.isFromOrder,
  };
};

class DashboardContainer extends Component {
  state = {
    isLoader: false,
    privilegeData:[]
  };

  prompTimer = null;
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.clearProductView();
    this.props.clearSortBy();
    this.props.changeActiveTab(0);
    clearBadgeCount();
    if (this.props.productId && this.props.productMerchantId) {
      this.props.setFromOrderFlag(true);
    }

    this.props.resetFilter();
    // this.props.resetSearchText();
    this.prompTimer = setTimeout(() => {
      this.props.installApp();
    }, 3000);

    if (getStorageItem("user")) {
      isPWAInstalled(JSON.parse(getStorageItem("user")).mobile_number);
      const privilegePayload = new FormData();
      const user = JSON.parse(getStorageItem("user"));
      privilegePayload.append("customer_id", user.id);
      privilegePayload.append("platform", apiData.platform);
      privilegePayload.append("merchant_id", apiData.merchant_id);
      // privilegePayload.append("filter_type", this.state.selectedIndex + 1);
      privilegePayload.append("status", 1);
      const privilegs = await stockAPI(
        privilegePayload,
        "POST",
        "/getCustomerPrivilegeVouchersList"
      );
      this.setState({
        privilegeData: privilegs,
      })

      // this.versionCheck();
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("userId", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);

      const bannerformData = new URLSearchParams();
      bannerformData.append("platform", apiData.platform);
      bannerformData.append(
        "banners_for",
        window.innerWidth < 481 ? 1 : window.innerWidth < 1024 ? 2 : 3
      );
      bannerformData.append("merchant_id", apiData.merchant_id);

      await this.props.merchantDetails(formData);
      await this.props.getBannerData(bannerformData);
      if (!this.props.productList.response) {
        this.setState(
          {
            isLoader: true,
          },
          async () => {
            const productData = new URLSearchParams();
            productData.append(
              "customer_id",
              JSON.parse(getStorageItem("user")).id
            );
            productData.append("platform", apiData.platform);
            productData.append("appVersion", apiData.appVersion);
            productData.append("last_id", 0);
            productData.append("merchant_id", apiData.merchant_id);
            productData.append("current_date", "");
            productData.append("sort_by", 601);
            productData.append("refine_by", null);
            productData.append("search_by", "");
            await this.props.getDashProductList(productData);
            this.setState({
              isLoader: false,
            });
          }
        );
      }

      if (
        this.props.merchantDetailsResponse &&
        this.props.merchantDetailsResponse.customerDetails.status !== 3
      ) {
        askForPermission();
      }
      this.props.getNotificationCount();
    } else {
      this.props.logoutUser();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.prompTimer);
    // this.props.clearProductList();
  }

  versionCheck = () => {
    const formData = new URLSearchParams();
    formData.append("merchant_id", apiData.merchant_id);
    formData.append("browser", platform.name);
    formData.append(
      "customer_id",
      getStorageItem("user") && JSON.parse(getStorageItem("user")).id
    );
    stockAPI(formData, "POST", "/appVersion").then(async (response) => {
      if (response) {
        if (getStorageItem("currVersion")) {
          const currVersion = getStorageItem("currVersion");
          if (currVersion !== response.app_version) {
            await setStorageItem("currVersion", response.app_version);
            (function (d, meta) {
              meta = d.createElement("meta");
              meta["http-equiv"] = "cache-control";
              meta.content = "no-cache";
              d.getElementsByTagName("head")[0].appendChild(meta);
            })(document);
            window.location.reload(true);
          } else {
            return;
          }
        } else {
          setStorageItem("currVersion", response.app_version);
        }
      }
    });
  };

  relatedProducts = async (e, productId, productMerchantId) => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.setFromOrderFlag(true);
    this.props.showLoader(true);
    await this.props.resetSearchText();
    await this.props.applyRelatedProducts(productId, productMerchantId);
    this.props.history.push("/redeem");
  };

  render() {
    const { isLoader } = this.state;
    const { activeTab } = this.props;
    return (
      <Fragment>
        <HeaderDashboard
          merchantDetailsResponse={this.props.merchantDetailsResponse}
        />
        <AlertMessage />
        <Loader open={isLoader} />
        <MyRoute dashboard={true} billUploadStatus={this.props.uploadingStatus}>
          <Dashboard
            merchantDetailsResponse={this.props.merchantDetailsResponse}
            productList={this.props.productList}
            productDetails={this.props.productDetails}
            getProductDetails={this.props.getProductDetails}
            logoutUser={logoutUser}
            showLoader={this.props.showLoader}
            banners={this.props.banners}
            relatedProducts={this.relatedProducts}
            isPrivilegeData={this.state?.privilegeData?.length > 0}
          />
        </MyRoute>
        <Grid item>
          <Footer />
        </Grid>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardContainer));
